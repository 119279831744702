<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Tipi abilitazione"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <v-btn text link @click="goToNew">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
    </template>
    <template v-slot:toolbar-extension>
      <div class="d-flex justify-center align-center" style="width: 100%">
        <SearchBar
          class="ml-2 mb-2"
          placeholder="Cerca tipi abiilitazione ..."
          :search-icon="null"
          expand-on-focus
        ></SearchBar>
      </div>
    </template>
    <template v-slot:content>
      <div style="height: 100%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento tipi abilitazione ..."
          :headers="headers"
          :loading="loading"
          :items="filteredEnablingTypes"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          :translator="translator"
          @edit="handleEdit"
          @delete="handleDelete"
          :show-select="false"
          @dblclick:row="handleEditDoubleClick"
        ></TypeDataTable>
      </div>
    </template>
  </FullScreenDialog >
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import { SearchBar } from "likablehair-ui-components";
import enablingTypesService from '@/services/enablingTypes/enablingTypes.service.js'

export default {
  name: "EnablingTypesRegistryList",
  components: {
    FullScreenDialog,
    SearchBar,
    TypeDataTable,
    StandardMenu
  },
  data: function() {
    let props = this.$router.resolve({name: 'Registries'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      enablingTypes: [],
      headers: [],
      page: 1,
      rowPerPage: 20,
      totalPages: 0,
      resizeListener: undefined
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.fetchEnablingTypes()

    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchEnablingTypes() {
      this.loadingItems = true
      enablingTypesService.list(this.page, this.rowPerPage).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page) {
          this.page = this.totalPages
        }

        this.enablingTypes = results.rows
        this.loadingItems = false
      })
    },
    fetchHeaders() {
      this.loadingHeaders = true
      enablingTypesService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    handleEdit(enablingType) {
      this.$router.push({
        name: 'EnablingTypesGeneralEditFormTab', 
        params: {
          id: enablingType.id
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'EnablingTypesRegistryList'}).href
        }
      })
    },
    handleEditDoubleClick(row , {item: enablingType}) {
      this.$router.push({
        name: 'EnablingTypesGeneralEditFormTab', 
        params: {
          id: enablingType.id
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'EnablingTypesRegistryList'}).href
        }
      })
    },
    handleDelete(enablingType) {
      let confirmed = confirm('Sei sicuro di voler eliminare ' + enablingType.code + ' ?')

      if(confirmed) {
        this.loading = true
        enablingTypesService.delete(enablingType).then(() => {
          this.fetchEnablingTypes()
          this.loading = false
        })
      }
    },
    goToNew() {
      this.$router.push({
        name: 'EnablingTypesGeneralNewFormTab',
        query: {
          pathToGoBack: this.$router.resolve({name: 'EnablingTypesRegistryList'}).href
        }
      })
    },
    translator(field, value) {
      if(field == 'enablingTypeCategories') {
        return value.code
      }
    }
  },
  computed: {
    filteredEnablingTypes() {
      return this.enablingTypes
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    }
  },
  watch: {
    page() {
      this.fetchEnablingTypes()
    },
    rowPerPage() {
      this.fetchEnablingTypes()
    },
  }
}
</script>

<style>

</style>